import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import ProjectPlanningApi from 'modules/admin/api/projectPlanningApi'

const initialState = {
    sortingLoad: true,
    autoNumbering: true,
    monthList: [],
    calculationData: null,
    updatedOn: new Date(),
    updateCalculations: new Date(),
    updateDeliverables: new Date(),
    hasData: false,
    deliverableData: null,
    planningData: null,
    staffColumns: [],
    lastTask: null,
    readOnly: false,
}

export const taskMonthListAsync = createAsyncThunk('taskMonthListAsync', async (data, thunkAPI) => {
    try {
        let response = await ProjectPlanningApi.getMonths()
        return response.data.rows
    } catch (error) {
        return thunkAPI.rejectWithValue()
    }
})

const projectPlanningSlice = createSlice({
    name: 'projectPlanning',
    initialState,
    reducers: {
        toggleSorting: (state) => {
            state.sortingLoad = !state.sortingLoad
        },
        toggleAutoNumbering: (state) => {
            state.autoNumbering = !state.autoNumbering
        },
        setCalculation: (state, action) => {
            state.calculationData = action.payload
        },
        setTaskUpdatedOn: (state) => {
            state.updatedOn = new Date()
        },
        updateTaskCalculations: (state) => {
            state.updateCalculations = new Date()
        },
        updateDeliverables: (state) => {
            state.updateDeliverables = new Date()
        },
        setTaskDataStatus: (state, action) => {
            state.hasData = action.payload
        },
        setDeliverableData: (state, action) => {
            state.deliverableData = action.payload
        },
        setPlanningData: (state, action) => {
            state.planningData = action.payload
        },
        resetPlanningSlice: (state) => {
            return { ...initialState, monthList: state.monthList }
        },
        setLastTask: (state, action) => {
            if (action.payload) {
                state.lastTask = action.payload
            } else {
                state.lastTask = { PhaseNo: 1, TaskNo: 0 }
            }
        },
        resetLastTask: (state, action) => {
            if (action.payload) {
                state.lastTask = action.payload
            } else {
                state.lastTask = { PhaseNo: 1, TaskNo: 0 }
            }
        },
        setStaffColumns: (state, action) => {
            state.staffColumns = action.payload
        },
        setReadOnly: (state, action) => {
            state.readOnly = action.payload
        },
    },
    extraReducers: {
        [taskMonthListAsync.fulfilled]: (state, action) => {
            state.monthList = action.payload
        },
    },
})

export const {
    toggleSorting,
    toggleAutoNumbering,
    setCalculation,
    setTaskUpdatedOn,
    setTaskDataStatus,
    setDeliverableData,
    setPlanningData,
    resetPlanningSlice,
    setLastTask,
    setStaffColumns,
    updateTaskCalculations,
    resetLastTask,
    updateDeliverables,
    setReadOnly,
} = projectPlanningSlice.actions

export default projectPlanningSlice.reducer
export const sortingLoad = (state) => state.projectPlanning.sortingLoad
export const autoNumbering = (state) => state.projectPlanning.autoNumbering
export const taskMonthList = (state) => state.projectPlanning.monthList
export const calculationData = (state) => state.projectPlanning.calculationData
export const taskUpdatedOn = (state) => state.projectPlanning.updatedOn
export const hasTaskData = (state) => state.projectPlanning.hasData
export const getDeliverableData = (state) => state.projectPlanning.deliverableData
export const getPlanningData = (state) => state.projectPlanning.planningData
export const getLastTask = (state) => state.projectPlanning.lastTask
export const getStaffColumns = (state) => state.projectPlanning.staffColumns
export const calculationUpdatedOn = (state) => state.projectPlanning.updateCalculations
export const deliverableUpdatedOn = (state) => state.projectPlanning.updateDeliverables
export const isReadOnly = (state) => state.projectPlanning.readOnly
