import api from 'utils/api'
// Function to find minimum height for the dataTable in a Grid
export function findHeight(height) {
    let rh
    let minHeight = height[0] // default height from state
    if (height.length > 1) {
        let [, ...newH] = height
        rh = Math.min(...newH)
    }

    if (rh) {
        return rh < minHeight ? minHeight : rh
    }

    return minHeight
}

export function checkNaN(value, returnValue = 0) {
    return isNaN(value) ? returnValue : value
}

export function isValid(value) {
    if (value === null || value === '' || value === 'null' || value === 'undefined' || value === undefined) {
        return false
    } else {
        return true
    }
}

export function generateNewRowId() {
    return `NR` + `${Date.now()}`.slice(-3)
}

export function digitCalc(value, active = false) {
    if (String(value).includes('.') && !active) {
        let dotValue = value.split('.')[1]
        return dotValue > 0 ? Number(value).toFixed(2) : value.split('.')[0]
    }
    return value
}

export function digitValidate(value) {
    if (String(value).includes('.')) {
        let dotValue = value.split('.')[1]
        return dotValue > 0 ? Number(value).toFixed(2) : value.split('.')[0]
    }
    return value
}

export function findZero(key, value) {
    if (value === '0' && (String(key).includes('ID') || String(key).includes('Week'))) {
        return null
    } else {
        return value
    }
}

export function capFirstLetter(text) {
    return !!text ? text[0].toUpperCase() + text.slice(1) : text
}

export function isEqual(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2) ? true : false
}

export function currencyFormatter(val = '') {
    let value = String(val)?.replace(/[^0-9.]/g, '') || ''
    if (String(value).includes('.')) {
        let wholeNum = value.split('.')[0]
        let dotValue = value.split('.')[1]
        return `${new Intl.NumberFormat('en-US').format(wholeNum.replaceAll(',', ''))}.${dotValue.slice(0, 2)}`
    } else if (String(value).includes(',')) {
        return new Intl.NumberFormat('en-US').format(value.replaceAll(',', ''))
    } else if (value) {
        return new Intl.NumberFormat('en-US').format(value)
    } else {
        return value
    }
}

export const CurrencyFormat = (value, decimal = 2, greaterThanZero = false) => {
    var whole, fraction
    if (greaterThanZero) {
        if (value <= 0) {
            return ''
        }
    }
    if (value === null) {
        return ''
    }
    if (isNaN(value)) {
        return ''
    } else {
        whole = Math.floor(value)
        fraction = value - whole
    }

    var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        maximumFractionDigits: fraction > 0 ? decimal : 0,
    })

    return formatter.format(value) /* 2500 - $2,500.00 */
}

export function NumberFormat(n, decimal = '', greaterThanZero = false, withPercentage = false, wholeNumber = true) {
    n = Number(n)
    if (isNaN(n)) n = 0
    if (greaterThanZero) {
        if (n <= 0) {
            return ''
        }
    }
    if (wholeNumber) {
        var result = n - Math.floor(n) !== 0
        var returnVal = 0
        if (result) {
            returnVal = Number(n.toFixed(decimal !== '' ? decimal : 1))
        } else {
            returnVal = n.toFixed(0)
        }
    } else {
        returnVal = n.toFixed(decimal)
    }
    return withPercentage ? `${returnVal}%` : returnVal
}

export function isNumber(event, maxLength = 0) {
    var value = event.target.value
    var charCode = event.which ? event.which : event.keyCode
    if (maxLength > 0) {
        if (charCode === 8) {
            return true
        }
        if (value.length >= maxLength) return event.preventDefault()
    }
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        return event.preventDefault()
    }
    if (charCode === 46 && value.indexOf('.') !== -1) {
        return event.preventDefault()
    }
    var number = value.split('.')
    if (window.getSelection().toString() === '') {
        var curPos = event.target.selectionStart
        var dotPos = value.indexOf('.')
        if (number.length > 1 && number[1].length >= 2 && curPos > dotPos) {
            return event.preventDefault()
        }
    }
    return true
}

export function isNumberAndNegative(event) {
    var value = event.target.value
    var charCode = event.which ? event.which : event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 45 && charCode !== 46) {
        return event.preventDefault()
    }
    if (event.target.tagName.toLowerCase() === 'textarea' || event.target.tagName.toLowerCase() === 'input') {
        var selText = value.substring(event.target.selectionStart, event.target.selectionEnd)
        if (!selText) {
            if (charCode === 45 && value.indexOf('-') !== -1) {
                return event.preventDefault()
            }
            if (value.indexOf('-') !== -1) {
                var negNumber = value.split('-')
                if (negNumber.length >= 1 && (negNumber[0].length > 0 || negNumber[0] !== '')) {
                    return event.preventDefault()
                }
            }
            if (charCode === 46 && value.indexOf('.') !== -1) {
                return event.preventDefault()
            }
            var number = value.split('.')
            var curPos = event.target.selectionStart
            var dotPos = value.indexOf('.')
            if (number.length > 1 && number[1].length >= 2 && curPos > dotPos) {
                return event.preventDefault()
            }
        }
    }
    return true
}

export const allowedNumberDecimal = (e) => {
    let value = e.target.value
    var regex = /^[+-]?[0-9]\.[0-9]{0,2}$/
    if (value.indexOf('.') !== -1 && String(value).split('.')[1].length > 1) {
        if (!regex.test(value)) {
            e.preventDefault()
        }
    }
}

export const convertAsNumber = (val) => {
    const nonNumericPattern = /[^\d.-]+/g
    const dollarDecimalPattern = /[$.]/g
    const cleanedInput = String(val).replace(nonNumericPattern, '')
    const dotInput = cleanedInput.replace(dollarDecimalPattern, '.')
    const numberValue = parseFloat(dotInput)
    return numberValue

    // var re = new RegExp('^$|^-?(\\d+)?(\\.?\\d*)?$')
    // return val.match(re) === null ? 0 : Number(val)

    //return val?.toString()?.replace(/\D/g, '')
}

export function gstCalculate(GSTValue) {
    return 1 + Number(GSTValue) / 100
}

export function getExcludeGST(amount, GSTValue = 10) {
    amount = Number(amount)
    let gstCal = gstCalculate(GSTValue)
    if (amount) {
        return Number(amount / gstCal).toFixed(2)
    } else {
        return 0
    }
}

export function getGSTFromValue(amount, GSTValue = 10) {
    amount = Number(amount)
    let gstCal = gstCalculate(GSTValue)
    if (amount) {
        return Number(amount - Number(amount / gstCal)).toFixed(2)
    } else {
        return 0
    }
}

export function includeGSTValue(amount, GSTValue = 10) {
    amount = Number(amount)
    let gstCal = gstCalculate(GSTValue)
    if (amount) {
        return Number(amount * gstCal).toFixed(2)
    } else {
        return 0
    }
}

// Sum array of object by key
export function getSumByKey(arr = [], key) {
    return arr.reduce(
        (accumulator, current) => accumulator + (isNaN(Number(current[key])) ? 0 : Number(current[key])),
        0
    )
}
// Sum array of object by key
export function getGreaterValueByKey(arr = [], key) {
    return arr.reduce(
        (accumulator, current) => (accumulator > Number(current[key]) ? accumulator : Number(current[key])),
        0
    )
}

export function awaitFunc(start, func) {
    start()
    return func()
}

export function downloadExcel(res) {
    const fileName = res.headers['content-disposition'].split('filename=')[1]
    const url = window.URL.createObjectURL(
        new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
    )
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
}
export function downloadCSV(res) {
    const fileName = res.headers['content-disposition'].split('filename=')[1]
    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'text/csv;charset=utf-8;' }))
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
}
export function downloadPDF(res) {
    const fileName = res.headers['content-disposition'].split('filename=')[1]
    const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/pdf' }))
    const link = document.createElement('a')
    link.href = url
    link.download = fileName.replaceAll('"', '')
    document.body.appendChild(link)
    link.click()
}

export function downloadWord(res) {
    const fileName = res.headers['content-disposition'].split('filename=')[1]
    const url = window.URL.createObjectURL(
        new Blob([res.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' })
    )
    const link = document.createElement('a')
    link.href = url
    link.download = fileName
    document.body.appendChild(link)
    link.click()
}

export const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType })
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

export function removeCookies(location) {
    var cookies = document.cookie.split('; ')
    for (var c = 0; c < cookies.length; c++) {
        var d = window.location.hostname.split('.')
        while (d.length > 0) {
            var cookieBase =
                encodeURIComponent(cookies[c].split(';')[0].split('=')[0]) +
                '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
                d.join('.') +
                ' ;path='
            var p = location.pathname.split('/')
            document.cookie = cookieBase + '/'
            while (p.length > 0) {
                document.cookie = cookieBase + p.join('/')
                p.pop()
            }
            d.shift()
        }
    }
}

export function getRandomNumber(min, max) {
    min = Math.ceil(min)
    max = Math.floor(max)
    return Math.floor(Math.random() * (max - min + 1) + min) // The maximum is inclusive and the minimum is inclusive
}

export const makeFullscreen = (ref) => {
    var elem = ref.current
    if (elem.requestFullscreen) {
        elem.requestFullscreen()
    } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen()
    } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen()
    }
}

// Func will return only unique objects in array
export function getUniqueArray(arr = [], compareProps = []) {
    let modifiedArray = []
    if (compareProps.length === 0 && arr.length > 0) compareProps.push(...Object.keys(arr[0]))
    arr.forEach((item) => {
        if (modifiedArray.length === 0) {
            modifiedArray.push(item)
        } else {
            if (
                !modifiedArray.some((item2) => compareProps.every((eachProps) => item2[eachProps] === item[eachProps]))
            ) {
                modifiedArray.push(item)
            }
        }
    })
    return modifiedArray
}

// Project Plannig - Number allow 0.125 but not allow multiple of 0.125
export function isNumber0125(event, maxLength = 0) {
    const value = event.target.value
    const charCode = event.which ? event.which : event.keyCode
    // Allow only numeric characters, backspace, and decimal point
    if (
        (charCode >= 48 && charCode <= 57) || // 0-9
        charCode === 8 || // Backspace
        charCode === 46 // Decimal point
    ) {
        // If the character is a decimal point, ensure it's valid in the context
        if (charCode === 46) {
            // Check if there's already a decimal point in the current value
            if (value.indexOf('.') !== -1) {
                event.preventDefault()
                return false
            }
        }
        // Check the entire input value (including the new character)
        const newValue =
            value.slice(0, event.target.selectionStart) +
            String.fromCharCode(charCode) +
            value.slice(event.target.selectionEnd)
        // Validate the format of the new value
        if (!isValidNumber(newValue)) {
            event.preventDefault()
            return false
        }
        return true
    }
    event.preventDefault()
    return false
}

function isValidNumber(value) {
    return /^\d*\.?\d{0,3}$/.test(value)
}

export function fileToBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
        reader.readAsDataURL(file) // Converts file to Base64 string
    })
}

export const DownloadByUrl = async (fileUrl, fileName) => {
    const response = api.get(fileUrl)
    const blob = new Blob([response.blob()], { type: 'pdf' })
    const a = document.createElement('a')
    a.download = fileName
    a.href = window.URL.createObjectURL(blob)
    const clickEvt = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true,
    })
    a.dispatchEvent(clickEvt)
    a.remove()
}

export const downloadFileFromBase64 = (base64String, filename) => {
    // Extract MIME type from filename or base64 string if available
    const mimeType = base64String.split(';')[0].split(':')[1] || 'application/octet-stream'

    // Remove the prefix if it exists (like "data:application/pdf;base64,")
    const base64Data = base64String.replace(/^data:[^;]+;base64,/, '')

    // Convert base64 to binary string
    const byteCharacters = atob(base64Data)
    const byteNumbers = new Uint8Array(byteCharacters.length)

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i)
    }

    // Create a Blob from the byte array
    const blob = new Blob([byteNumbers], { type: mimeType }) // Use the detected MIME type

    // Create a link element
    const link = document.createElement('a')
    const url = URL.createObjectURL(blob)

    // Set the link attributes
    link.href = url
    link.download = filename // Set the desired filename with extension
    document.body.appendChild(link) // Append the link to the body
    link.click() // Trigger the download
    document.body.removeChild(link) // Clean up
    URL.revokeObjectURL(url) // Release the object URL
}

export function getFileNameWithExtension(filename) {
    const extension = filename.split('.').pop() // Extract file extension
    const baseName = filename.slice(0, -extension.length - 1) // Slice out the extension

    // Check if the filename length is more than 35 characters
    let slicedFileName = filename
    if (baseName.length > 30) {
        slicedFileName = baseName.slice(0, 27) + '... .' + extension // Keep only the last 35 characters
    }

    return slicedFileName
}
