import { useCallback, useState } from 'react'
// Mui
import styled from '@emotion/styled'
// Components
import AlertBox from 'components/AlertBox'
import ErrorBoundary from 'components/ErrorBoundary'
import Footer from './footer/Footer'
import Header from './header/Header'
import Sidebar from './sidebar/Sidebar'
// Custom Styles
import palette from '../../../theme/palette'
// Config
import { SIDEBAR_MAX_WIDTH, SIDEBAR_MIN_WIDTH } from '../../../constants/config'
// Redux
import { alertState } from 'app/slices/alertSlice'
import { useSelector } from 'react-redux'

function FormAlert() {
    const state = useSelector(alertState)
    return <AlertBox state={state} />
}

const AdminLayout = ({ children }) => {
    // Sidebar open state
    const [isSidebarOpen, setIsSidebarOpen] = useState(true)

    // Sidebar Open state handler
    const handleToggle = useCallback(() => {
        setIsSidebarOpen((p) => !p)
    }, [])

    return (
        <>
            <RootLayout>
                <Sidebar open={isSidebarOpen} handleToggle={handleToggle} />
                <BodyContainer open={isSidebarOpen}>
                    <Header handleToggle={handleToggle} open={isSidebarOpen} />
                    <Wrapper>
                        <ErrorBoundary>{children}</ErrorBoundary>
                    </Wrapper>
                    <Footer />
                    <FormAlert />
                </BodyContainer>
            </RootLayout>
        </>
    )
}

export default AdminLayout

// styles
const RootLayout = styled('main')({
    position: 'relative',
    // display: "flex",
    height: '100%',
    minHeight: '100vh',
})

const Wrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(1.2),
    paddingTop: 0,
}))

const BodyContainer = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    position: 'relative',
    flexGrow: 1,
    minHeight: '100%',
    padding: 0,
    backgroundColor: palette.body.main,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.standard,
    }),
    [theme.breakpoints.up('md')]: {
        marginLeft: `${open ? SIDEBAR_MAX_WIDTH : SIDEBAR_MIN_WIDTH}px`,
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: 0,
    },
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.standard,
        }),
    }),
    paddingBottom: 50,
}))
