import React from 'react'
// Mui
import MuiAlert from '@mui/material/Alert'
import { Snackbar as MuiSnackbar } from '@mui/material'
// Redux
import { useDispatch, useSelector } from 'react-redux'
import { hideSnackbar, snackbarState } from '../app/slices/snackbarSlice'

const Snackbar = () => {
    const dispatch = useDispatch()
    const { isVisible, title, type = 'success', duration } = useSelector(snackbarState)

    // Func to close snackbar
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        dispatch(hideSnackbar())
    }
    return (
        <MuiSnackbar
            open={isVisible}
            autoHideDuration={duration}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            sx={{ top: { xs: 75 } }}
        >
            <MuiAlert
                onClose={handleClose}
                severity={type}
                sx={{
                    width: '100%',
                    '& .MuiAlert-message': { whiteSpace: 'pre-line', color: type === 'warning' ? '#000' : '#fff' },
                }}
                variant="filled"
            >
                {title}
            </MuiAlert>
        </MuiSnackbar>
    )
}

export default Snackbar
